.basicButton {
    justify-content: center;
    align-items: center;
    outline: 0;
    color: white;
    background-color: #00afc9;
    font-family: 'PFDinTextPro-Light';
    font-weight: 800;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
}
.basicButton:hover {
    text-decoration: underline;
}
.textLink {
    font-family: 'ChevinPro-Light';
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid #00afc9;
    color: #00afc9;
}
.heading {
    font-size: 48px;
    color: black;
    text-align: center;
    font-family: 'DIN Round Pro';
}
.textButton {
    color: #00AFC9;
    margin: 16px 12px 0 0;
}
@media (max-width:1000px) {
    .heading {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .textButton {
        margin-right: 15px;
        text-align: end;
    }
}