.container {
    display: flex;
    position: relative;
}
.fillerText {
    font-family: 'PFDinTextPro-Light';
    font-size: 16px;
    margin: 1% 0;
}
.backgroundPic {
    background-image: url(../../assets/images/fone.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.sectionPanelDefault {
    padding-top: 2.5%;
    width: 50%;
    margin: 3% 7%;
}
.infoPanel {
    padding-left: 2%;
    padding-right: 13%;
    border: 10px solid transparent;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-right: 0%;
    background-color: rgba(219, 250, 255, 0.8);
}
.registrationForm {
    padding-left: 13%;
    padding-right: 3%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 10px solid rgb(256, 256, 256);
    margin-left: 0;
    background-color: rgb(256, 256, 256);
    height: 576px;
    overflow-x: hidden;
}
.scrollable::-webkit-scrollbar {
    width: 10px;
}
.scrollable::-webkit-scrollbar-thumb {
    height: 46px;
    border-radius: 5px;
    background-color: #a1a1a1;
}
.scrollable::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(161, 161, 161, 0.14901960784313725);
}
.blueText {
    color: #00AFC9;
}
.doctorPic {
    display: flex;
    position: absolute;
    /* left: 37%; */
    /* right: 40%; */
    right: calc(50% - 11rem);
    /* top: 24.5%; */
    bottom: 0%;
    width: 30%;
    max-width: 411px;
    height: 97%;
    max-height: 660px;
}
.heading {
    font-family: 'DIN Round Pro';
    font-size: 38px;
    margin-bottom: 3%;
}
.sectionText {
    padding-bottom: 5%;
    margin-bottom: 1%;
}
.progress {
    display: flex;
    font-weight: bolder;
}
.counter {
    color: #A1A1A1;
    margin-right: 13px;
}
.progressText {
    font-size: 18px;
    font-family: 'DIN Round Pro';
}
.star {
    color: red;
}
.inputSection {
    margin-bottom: 20px;
}
.doctorInput {
    width: 100%;
    font-family: 'PFDinTextPro-Light';
    font-size: 16px;
    min-height: 30px;
    font-style: italic;
    border: none;
    outline: none;
}
.standardBorder {
    border-bottom: 2px solid #00AFC9;
}
.errorBorder {
    border-bottom: 2px solid red;
}
.buttonNext {
    display: flex;
    padding: 3%;
    min-width: 130px;
    height: 55px;
    margin-right: 10px;
}
.buttonInfo {
    display: flex;
    width: 165px;
    height: 65px;
}
.mobilePanel {
    display: none;
}
.dropdownBorder {
    border-bottom: 1px solid rgb(118, 118, 118);
}
.dropdownInput {
    background-color: #f8f8f8;
    cursor: pointer;
    margin-bottom: 0;
}
.dropdownInputWrapper {
    margin-left: -18px;
    margin-right: -18px;
    border-radius: 18px;
    margin-top: 16px;
    background-color: #f8f8f8;
    padding: 12px;
}
.dropdownList {
    z-index: 1;
    overflow-y: scroll;
    position: absolute;
    margin-left: -18px;
    top: 68px;
    width: 100%;
    max-height: 150px;
    border: 18px solid #f8f8f8;
    background-color: #f8f8f8;
    box-sizing: content-box;
    border-radius: 0 0 18px 18px;
}
.dropdownWrapper {
    position: relative;
}
.dropdownWrapperSpecialty, .dropdownWrapperSpecialty .dropdownInput, .dropdownWrapperSpecialty .dropdownInputWrapper, .dropdownWrapperSpecialty .dropdownList {
    background-color: #e5f7f9;
}
.listElement {
    padding: 2% 1%;
}
.listElement:hover {
    width: 100%;
    cursor: pointer;
}
.dropdownWrapper .listElement:hover {
    background-color: rgba(161, 161, 161, 0.14901960784313725);
}
.dropdownListSpecialty .listElement:hover {
    background-color: LightBlue;
}
.dropdownListSpecialty {
    background-color: #e5f7f9;
    border: 18px solid #e5f7f9;
}
.dropdownListSpecialty::-webkit-scrollbar-thumb {
    background-color: #00afc9;
}
.dropdownListSpecialty::-webkit-scrollbar-track {
    background-color: rgba(0, 175, 201, 0.10196078431372549);
}
.dropdownArrow {
    position: absolute;
    border: 6px solid transparent;
    right: 0;
    pointer-events: none;
}
.dropdownArrowUp {
    top: 50px;
    border-color: #a1a1a1 transparent transparent transparent;
}
.dropdownArrowDown {
    bottom: 25px;
    border-color: transparent transparent #a1a1a1 transparent;
}
.dropdownWrapperSpecialty .dropdownArrowUp {
    border-color: #00AFC9 transparent transparent transparent;
}
.dropdownWrapperSpecialty .dropdownArrowDown {
    border-color: transparent transparent #00AFC9 transparent;
}
.cursive {
    font-size: 14px;
    font-style: italic;
}
.roundBorderInput {
    border-radius: 18px;
    border: 1px dotted rgb(118, 118, 118);
    padding: 5%;
    cursor: pointer;
}
.buttonRow {
    display: flex;
}
.termsOfUseText {
    margin: 5% 0;
    font-family: 'PFDinTextPro-Light';
    font-size: 12px;
    color: #d2d5d2;
}
.docLink {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.inputTab {
    margin: 3% 0;
    max-width: 350px;
}
.popupBox {
    display: flex;
    position: relative;
    margin: 2%;
    flex-direction: column;
    justify-content: center;
    top: -200px;
    min-width: 300px;
    max-height: 250px;
    height: 40%;
    z-index: 6;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 36px;
}
.popupHead {
    text-align: center;
}
.popupText {
    text-align: center;
    margin: 5% 10%;
}
@media (max-width:1200px) {
    .doctorPic {
        right: calc(50% - 9rem);
    }
}
@media (max-width:1000px) {
    .container {
        flex-direction: column;
        align-items: center;
        padding: 0 4% 2%;
    }
    .backgroundPic {
        opacity: 0.5;
        background-repeat: repeat;
        background-size: auto;
    }
    .mobilePanel {
        display: flex;
    }
    .registrationForm {
        padding: 3% 10%;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 20px;
        overflow: visible;
    }
    .infoPanel {
        display: none
    }
    .doctorPic {
        display: none
    }
    .buttonNext {
        margin-left: auto
    }
    .inputTab {
        max-width: 100%;
    }
    .doctorPicMini {
        height: 100%;
        width: 40%;
        min-height: 220px;
        max-height: 270px;
        z-index: -1;
        top: 25px;
        right: 8%;
        position: relative;
    }
    .infoPanelMini {
        font-size: 14px;
        margin: 3%;
    }
    .mobileButton {
        display: flex;
        cursor: pointer;
        text-decoration: underline;
        align-items: center;
    }
    .plane {
        margin: 2%;
    }
    .popupBox {
        top: 0;
    }
}
@media (max-width:350px) {
    .doctorPicMini {
        display: none;
    }
    .heading {
        font-size: 32px;
    }
}