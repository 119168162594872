.container {
    background-color: #e5f7f9;
    padding: 30px 16%;
}
.linkRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
}
.linkStyle {
    width: 25%;
}
.linkStyleCenter {
    width: 35%;
}
.linkStyle .linkActive {
    display: none
}
.linkStyle:hover .linkInactive {
    display: none
}
.linkStyle .linkInactive {
    display: flex;
    width: 100%;
    height: 100%;
}
.linkStyle:hover .linkActive {
    display: flex;
    width: 100%;
    height: 100%;
}
@media (max-width:1000px) {
    .container {
        padding: 40px 0;
    }
    .linkRow {
        flex-direction: column;
        align-items: center;
    }
    .linkStyle {
        width: 50%;
    }
}