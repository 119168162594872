.container {}
.menu {
    display: flex;
    margin: 12px;
    padding: 0 7%;
}
.links {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center
}
.auth {
    display: flex;
    flex-wrap: wrap;
}
.topnav {
    text-decoration: none;
    cursor: pointer;
    color: black;
    padding-bottom: 10px;
    font-size: 20px;
}
.topnav:hover {
    padding-bottom: 5px;
    border-bottom: 5px solid #00afc9;
}
.input {
    color: rgba(0, 0, 0, 0.6);
    border: 0;
    height: 32px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    padding-left: 30px;
    background-color: #f6f6f6;
    border-radius: 3px;
}
.input:focus {
    outline: 0;
}
.inputMail {
    width: 250px;
    background-image: url(/src/assets/images/mailico.png);
}
.inputPassword {
    width: 63%;
    background-image: url(/src/assets/images/passwordico.png);
}
.inputWrong {
    border: 1px solid red
}
.passwordLink {
    margin-left: 10px;
}
.mobileBar {
    display: none
}
.mobileElement {
    display: none
}
.secondRow {
    display: flex;
    align-items: center;
    margin-top: 5px;
    width: 250px
}
.buttonSumbit {
    display: flex;
    width: 33%;
    margin-left: auto;
    height: 34px;
    font-size: 0.9em;
}
.logo {
    width: 80%;
    max-width: 320px;
}
@media (max-width:1000px) {
    .mobileBar {
        display: flex;
        width: 100%;
        align-items: center;
    }
    .mobileBarButton {
        cursor: pointer;
        margin-left: auto;
    }
    .mobileElement {
        display: flex;
    }
    .fadeIn {
        max-height: 0;
        background-color: white;
        transition: 1s ease-out;
        position: absolute;
        pointer-events: none;
        z-index: 1;
        width: 100%;
    }
    .fadeOut {
        max-height: 100%;
        background-color: white;
        transition: 1s ease-in;
        position: absolute;
        pointer-events: auto;
        z-index: 1;
        width: 100%;
        padding: 2%;
    }
    .fadeIn .mobileElement {
        transition: 0.5s ease-in;
        transform: scaleY(0);
        opacity: 0;
    }
    .fadeOut .mobileElement {
        transition: 0.5s ease-in;
        transform: scaleY(1);
        opacity: 1;
    }
    .auth {
        justify-content: center;
    }
    .desktopElement {
        display: none;
    }
    .input {
        margin: 0 10px 0 0;
    }
    .topnav {
        margin-bottom: 10px;
    }
}
@media (max-width:550px) {
    .inputMail {
        width: 90%;
        margin: 0 0 5% 0;
    }
    .secondRow {
        width: 90%;
        margin-bottom: 2%;
    }
    .inputPassword {
        width: 65%;
    }
    .buttonSumbit {
        width: 35%;
    }
    .mobileBar {
        width: 35%;
    }
}