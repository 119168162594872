.popupBackground {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 7;
    width: 100%;
    height: 100vh;
    background: #00000050;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s;
}
.popupActive {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 1s;
}