.container {
    display: flex;
    background-color: #e5f7f9;
    padding-top: 3%;
    justify-content: center;
    padding-left: 9.5%;
    padding-right: 9.5%;
    background-size: cover;
    padding-bottom: 20px;
}
.containerFixed {
    bottom: 0;
    position: absolute;
}
.footerInfoRow {
    display: flex;
    max-width: 1300px;
}
.footerLinks {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: space-evenly;
}
.footerRightContainer {
    width: 9%;
    text-align: center;
}
.footerLink {
    font-family: 'PFDinTextPro-Light';
    color: #00afc9;
    text-decoration: underline;
    margin-right: 1%;
    cursor: pointer;
}
.footerLinkAfterFirst {
    border-left: #00afc9 solid 2px;
    padding-left: 1%;
}
.footerInfoSection {
    font-family: "PFDinTextPro-Light";
    font-size: 12px;
    color: #abaeae;
    margin: 2% 0;
}
.footerAgeRestriction {
    margin-top: auto;
    margin-bottom: auto;
    /* margin-left: 6.7%; */
    border: #00afc9 2px solid;
    color: #00afc9;
    border-radius: 3px;
    padding: 1.6%;
    font-family: "PFDinTextPro-Light";
    font-size: 40px;
}
.footerLinksSection {
    display: flex;
}
@media (max-width:1000px) {
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .footerLinks {
        width: 80%;
    }
    .footerLinksSection {
        flex-direction: column;
    }
    .footerLink {
        margin-bottom: 10px;
        font-size: 0.8em;
        border: 0;
        padding: 0;
    }
    .footerAgeRestriction {
        padding: 20% 10%;
        font-size: 1.7rem;
    }
    .footerRightContainer {
        width: 20%;
    }
}