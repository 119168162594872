* {
    box-sizing: border-box;
}
input {
    padding: 0;
}
@font-face {
    font-family: "ChevinPro-Light";
    src: url("../src/assets/fonts/ChevinPro-Light/ChevinPro-Light.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'PFDinTextPro-Light';
    src: url("../src/assets/fonts/PFDinTextPro-Light/PFDinTextPro-Light.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Round Pro';
    src: url("../src/assets/fonts/DINRoundPro/DINRoundPro.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Round Pro';
    src: url("../src/assets/fonts/DINRoundPro/DINRoundPro-Bold.ttf");
    font-style: normal;
    font-weight: bolder;
    font-display: swap;
}
@font-face {
    font-family: 'DIN Round Pro';
    src: url("../src/assets/fonts/DINRoundPro/DINRoundPro.ttf");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}