.container {
    font-family: 'ChevinPro-Light';
    min-height: 85vh;
}
.loadingPanel {
    text-align: center;
    /* height: 85vh; */
    padding: 15%;
    font-size: 24px;
    font-family: 'DIN ROUND PRO';
}
.popupBox {
    display: flex;
    position: relative;
    margin: 2%;
    flex-direction: column;
    justify-content: center;
    top: -200px;
    min-width: 300px;
    max-height: 250px;
    height: 40%;
    z-index: 6;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 36px;
}
.popupHead {
    text-align: center;
}
.userBasicPersonalInfoRow {
    display: flex;
    align-items: center;
    padding: 2% 5%;
    border-bottom: 1px solid rgb(118, 118, 118);
}
.userChangeRow {
    padding: 0 5%;
    border-bottom: 1px solid rgb(118, 118, 118);
    font-size: 24px;
    display: flex
}
.nameButton {
    margin-right: 18px;
    padding: 12px 40px;
    border: 1px solid rgb(118, 118, 118);
    border-bottom: 0px;
    border-top-left-radius: 5px;
    cursor: pointer;
    border-top-right-radius: 5px;
}
.userFullName {
    padding: 2% 5%;
    border-bottom: 1px solid rgb(118, 118, 118);
    font-size: 24px;
    font-family: 'DIN ROUND PRO';
}
.userDetailsDimplomaSection {
    padding: 1% 5% 2%;
    border-bottom: 1px solid rgb(118, 118, 118);
    font-size: 20px;
}
.userDetailsReviews {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2%;
    font-size: 20px;
}
.userDetailedPersonalInfo {
    padding: 2% 5%;
    border-bottom: 1px solid rgb(118, 118, 118);
}
.userPersonalInfoNameAndAvatar {
    display: flex;
    width: 60%;
}
.userPersonalInfoName {
    display: flex;
    font-size: 24px;
    font-family: 'DIN ROUND PRO';
    align-items: center;
    margin: 3%;
}
.checkbox {
    height: 100%;
    width: 18px;
    margin: 0;
    border: #00afc9;
}
.userPersonalInfoAvatar {
    border-radius: 100%;
    border: #00afc9 2px solid;
    margin: 12px;
    color: #00afc9;
    padding: 3%;
    font-family: "PFDinTextPro-Light";
    font-size: 40px;
    user-select: none;
}
.userPersonalInfoChangeButton {
    margin-left: auto;
    width: 30%;
}
.changeButton {
    display: flex;
    justify-content: center;
    color: white;
    background-color: #00afc9;
    font-family: 'PFDinTextPro-Light';
    align-items: center;
    font-weight: 800;
    border-radius: 5px;
    padding: 1%;
    min-width: 100%;
    min-height: 50px;
    cursor: pointer;
}
.changeButton:hover {
    text-decoration: underline
}
.saveButton {
    min-width: 0;
    max-width: 250px;
    width: 40%;
    border: none;
    margin: 1% 0 0 auto;
    font-size: 16px;
}
.userInfoRow {
    display: flex;
    margin: 1%;
}
.userInfoLabel {
    width: 40%;
    font-weight: 700;
    font-size: 20px;
}
.saveSuccess {
    color: #00AFC9;
    width: 100%;
    padding: 1%;
}
.saveError {
    color: red;
    width: 100%;
    padding: 1%;
}
.userInfoContent {
    min-height: 30px;
    font-size: 20px;
    font-style: italic;
    border: none;
    outline: none;
    border-bottom: 2px solid #00AFC9;
    font-family: 'ChevinPro-Light';
}
.userInfoContentWrapper {
    width: 60%;
}
.userInfoContentWrapper .userInfoContent {
    width: 100%;
}
.errorBorder {
    border-bottom: 2px solid red;
}
.dropdownList {
    z-index: 1;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    font-size: 20px;
    font-style: italic;
    margin-left: -18px;
    margin-right: -18px;
    width: 100%;
    max-height: 150px;
    border: 18px solid white;
    background-color: white;
    box-sizing: content-box;
    border-radius: 18px;
}
.listElement {
    cursor: pointer;
}
.backButton {
    color: #00AFC9;
    margin: 16px 12px 0 0;
}
.backButtonText {
    border-bottom: 1px solid #00AFC9
}
.buttonRow {
    display: flex;
}
.userDetailsDimplomaPanel {
    width: 100%;
    display: flex;
    margin: 1% 0;
    flex-direction: column;
}
.userDetailsParametersPanel {
    display: flex;
    border: 1px dotted rgb(118, 118, 118);
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    background-color: #f8f8f8;
}
.userDetailsValuesPanel {
    display: flex;
    border: 1px dotted rgb(118, 118, 118);
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
}
.userDetailsDimplomaParameterName {
    padding: 12px;
    width: 60%;
}
.userDetailsDimplomaParameterSize {
    display: flex;
    padding: 12px;
    width: 40%;
    border-left: 1px dotted rgb(118, 118, 118);
}
.userDetailsDimplomaDelete {
    margin-left: auto;
    cursor: pointer;
}
.userDetailsDropzone {
    display: flex;
    padding-top: 12px;
    margin: 1% 0;
    flex-direction: column;
    border: 1px dotted rgb(118, 118, 118);
    border-radius: 18px;
    cursor: pointer;
    background-color: #e5f7f9;
}
.userDetailsFilterDatePickerWrapper {
    display: flex;
    font-size: 10px;
    padding-top: 12px;
    margin: 1%;
    border: 1px dotted rgb(118, 118, 118);
    border-radius: 18px;
    background-color: rgb(239, 242, 247);
}
.userDetailsDropzone:hover {
    background-color: lightblue
}
.userDropzoneLabel {
    padding: 12px 12px 0;
}
.userDropzoneText {
    padding: 0 12px 12px;
    font-style: italic;
}
.deleteButton {
    max-height: 25px;
}
.userDetailsReviewParameterSource {
    padding: 12px;
    width: 40%;
    display: flex;
    align-items: center;
}
.userDetailsReviewParameterDate {
    padding: 12px;
    width: 30%;
    border-left: 1px dotted rgb(118, 118, 118);
}
.userDetailsReviewParameterLink {
    padding: 12px;
    width: 30%;
    border-left: 1px dotted rgb(118, 118, 118);
}
.userDetailsValuesMidPanel {
    border-radius: 0px;
}
.userDetailsSourceName {
    margin-left: 5px;
}
.userInfoDateWrapper .userInfoContent {
    width: 100%;
    cursor: pointer;
}
.userDetailsFilter {
    display: flex;
    flex-direction: column;
    margin: 1%;
}
.userDetailsFilterDatePicker {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-align: center;
}
.userDetailsFilterDates {
    margin-top: 10px;
}
.dropDownSourceInput {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
    outline: 0
}
@media (max-width:600px) {
    .userInfoLabel, .userDetailsDimplomaSection, .backButton, .userDetailsReviewParameterSource, .userDetailsReviewParameterDate, .userDetailsReviewParameterLink {
        font-size: 12px;
    }
    .checkbox {
        height: 12px;
    }
    .userInfoContent {
        min-height: 10px;
        font-size: 12px;
    }
    .userDetailsReviews {
        flex-wrap: wrap-reverse;
    }
    .saveButton {
        max-width: 100%;
        width: 100%;
    }
    .deleteButton {
        max-height: 12px;
    }
    .saveSuccess, .saveError {
        font-size: 18px;
    }
    .popupBox {
        top: 0;
    }
}