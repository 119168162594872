.container {
    display: flex;
    background-position: center;
    background-size: contain;
    background-image: url(../../assets/images/icons.png);
    width: 100%;
    background-repeat: no-repeat;
}
.secondBackImage {
    display: none
}
.textContainer {
    width: 23%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20.8%;
    margin-bottom: 15%;
    text-align: center;
}
.heading {
    font-family: 'DIN Round Pro';
    /* font-size: 2.6rem; */
    font-size: 2.3vw;
    margin-bottom: 5%;
}
.text {
    font-family: 'PFDinTextPro-Light';
    font-size: 1rem;
    margin-bottom: 5%;
}
.scanButton {
    display: flex;
    padding: 5%;
    max-width: 130px;
    min-height: 50px;
}
@media (max-width:1100px) {
    .textContainer {
        width: 30%;
    }
    .text {
        font-size: 0.7rem;
    }
    .scanButton {
        font-size: 0.8rem;
        min-height: 40px;
    }
}
@media (max-width:800px) {
    .textContainer {
        margin-top: 25%
    }
    .heading {
        font-size: 1.1rem;
        margin-bottom: 3%;
    }
}
@media (max-width:600px) {
    .container {
        background-image: none;
    }
    .heading {
        font-size: 1.3rem;
    }
    .textContainer {
        width: 50%;
        margin-top: 75%;
        margin-bottom: 75%;
    }
    .secondBackImage {
        display: block;
        position: absolute;
        z-index: -1;
        height: auto;
        width: 100%;
    }
}
@media (max-width:400px) {
    .textContainer {
        width: 80%;
    }
}