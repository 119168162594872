.container {
    background-color: white;
    padding: 30px 10%;
}
.doctorReviewRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
    margin-top: 40px;
}
.doctorReview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}
.doctorPicture {
    border-radius: 100%;
    margin-bottom: 12px;
    max-height: 120px;
}
.doctorName {
    font-size: 24px;
    color: #00AFC9;
    white-space: nowrap;
    min-height: 32px;
}
.doctorLink {
    width: 24px;
}
.doctorSpecialty {
    color: grey;
    text-align: center;
    height: 36px;
    max-height: 36px;
    margin: 0px -30px 20px;
}
.doctorReviewText {
    background-color: #f9f9f9;
    padding: 25px;
    flex-grow: 1;
    cursor: pointer;
    border-radius: 18px;
}
.popupBox {
    width: 70%;
    position: relative;
    background-color: #fff;
    display: flex;
    padding: 45px 70px 70px 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 6;
    border-radius: 36px;
    outline: 0;
}
.reviewContainerInPopup {
    display: flex;
}
.doctorPictureInPopup {
    width: 37%;
    margin-top: 20px;
    height: 100%;
    border-radius: 100%;
}
.reviewContentsInPopup {
    margin: 2%;
}
.reviewTextInPopup {
    font-family: 'PFDinTextPro-Light';
    margin: 2% 0;
    max-height: 400px;
    overflow: auto;
}
.doctorNameInPopup {
    font-family: 'DIN Round Pro';
    font-size: 38px;
    margin-bottom: 3%;
}
.doctorLinkInPopup {
    display: flex;
    justify-content: flex-end;
}
.reviewTextInPopup::-webkit-scrollbar {
    margin-right: 20px;
    width: 10px;
}
.reviewTextInPopup::-webkit-scrollbar-thumb {
    width: 10px;
    height: 46px;
    border-radius: 5px;
    background-color: #a1a1a1;
}
.reviewTextInPopup::-webkit-scrollbar-track {
    width: 10px;
    border-radius: 5px;
    background-color: rgba(161, 161, 161, 0.14901960784313725);
}
@media (max-width:1000px) {
    .popupBox {
        display: flex;
        width: 80%;
        padding: 3%;
        height: 600px;
    }
    .doctorReviewRow {
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .reviewContainerInPopup {
        flex-direction: column;
        padding: 3%;
        align-items: center;
    }
    .doctorNameInPopup {
        font-size: 20px;
        text-align: center;
    }
    .reviewTextInPopup {
        max-height: 220px;
    }
    .doctorReview {
        width: 100%;
    }
}